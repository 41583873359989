<template>
  <div>
    <el-dialog title="修改记录" :visible.sync="visible" :close-on-click-modal="false" width="850px">
      <el-table v-loading="loading" :data="list" max-height="500px">
        <el-table-column type="index" label="序号" align="center"></el-table-column>
        <el-table-column label="修改人" align="center">
          <template slot-scope="{ row }">
            {{row.updateAdminName}}-{{row.updateAdminAcc}}
          </template>
        </el-table-column>
        <el-table-column label="修改时间" align="center" prop="createTime"></el-table-column>
        <el-table-column label="修改类型" align="center">
          <template slot-scope="{ row }">
            {{ row.modifyType ? optionsFilter(row.modifyType, modifyTypeOption) : '--'}}
          </template>
        </el-table-column>
        <el-table-column label="修改内容" align="center" prop="updateContent"></el-table-column>
      </el-table>
      <span slot="footer" class="dialog-footer">
      </span>
    </el-dialog>
  </div>
</template>

<script>
import _api from "@/utils/request";
export default {
  name: "ChangeApplyRecordDia",
  data() {
    return {
      visible: false,
      row: {},
      loading: false,
      list: [],
      modifyTypeOption: [
        { label: '修改补差金额', value: '00' },
        { label: '修改打款信息', value: '01' },
      ]
    };
  },
  methods: {
    open(row) {
      this.row = row
      this.getList()
      this.visible = true
    },
    getList() {
      this.loading = true
      _api.aprHonorModifyList({
        subArgueNo: this.row.subArgueNo
      }).then(res => {
        console.log(res.data)
        this.list = res.data
      }).finally(() => {
        this.loading = false
      })
    },
    optionsFilter(v, options) {
      return options.find(item => item.value == v)?.label
    },
    close() {
      this.visible = false
    },
    confirm() {

    }
  }
};
</script>
