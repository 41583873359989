<template>
  <el-dialog title="查看收货地址" :visible.sync="visible" :close-on-click-modal="false" width="500px">
    <div class="address-box">
      <div class="mb-10" style="color:#0981FF;font-size:16px">收件人信息</div>
      <div style="margin-bottom:5px">
        {{ row.linkman }}-{{ row.mobile }}
      </div>
      <div>
        {{row.province}}{{row.city}}{{row.area}}{{row.address}}
      </div>
    </div>
    <span slot="footer" class="dialog-footer">
      <el-button @click="visible = false">取消</el-button>
      <el-button type="primary" @click.native="confirm">确定</el-button>
    </span>
  </el-dialog>
</template>
<script>
import _api from "@/utils/request";
export default {
  name: "AddressInfoDia",
  data() {
    return {
      visible: false,
      row: {},
    };
  },
  methods: {
    open(row) {
      this.row = row
      this.visible = true
    },
    close() {
      this.visible = false
    },
    confirm() {
      this.close()
      this.$emit('confirm')
    }
  }
};
</script>

<style lang="scss" scoped>
.address-box {
  background-color: #f5f6fa;
  color: #666;
  width: 400px;
  margin: auto;
  border-radius: 10px;
  margin-bottom: 10px;
  padding: 10px 20px;
  box-sizing: border-box;
  border: 1px solid transparent;
}
</style>