<template>
  <div class="box">
    <div class="header">
      <div class="title">
        <!-- <img src="../../../assets/images/icon_cydd_.png" alt=""> -->
        <h3>荣耀追差订单</h3>
        <!-- <p>处理、查看回收商发起的差异订单，并完成打款</p> -->
        <!--        <p class="order_num">共有<span class="lv_fc_red" style="text-decoration: underline;">{{ num }}</span>单超5天未处理-->
        <!--        </p>-->
      </div>
      <div class="search">
        <GlobalForm
          :inline="true"
          :init-data="initData"
          config-icon="el-icon-search"
          :form-item-list="formItemList"
          confirm-btn-name="查询"
          @handleChange="handleChange"
          @handleConfirm="handleConfirm"
        >
        </GlobalForm>
      </div>
    </div>
    <div class="body" v-loading="tableData.loading">
      <div class="tab_action">
        <TabSelect
          :tab-data="tabData"
          :activeIndex="activeIndex"
          border-radius="4px"
          @tabAction="tabAction"
        />
        <div class="taab_left">
          <!-- <el-button v-if="activeIndex === 1" type="warning" @click.native="importVisible = true"
            size="mini">导入线下打款订单</el-button> -->
          <div class="excel_btn" @click="excelClick">
            <img src="../../../assets/images/excel_dc.png" alt="" />
            <p>导出Excel</p>
          </div>
        </div>
      </div>
      <div
        v-if="tableData.data.length <= 0"
        style="
          width: 100%;
          text-align: center;
          padding: 80px 0px;
          font-size: 24px;
          color: rgb(102, 102, 102);
          background: white;
          font-weight: bold;
        "
      >
        暂无订单~
      </div>
      <div class="table" v-for="(item, index) in tableData.data" :key="index">
        <div class="table_header">
          <div class="table_header_left">
            <p class="table_index">{{ item.index }}</p>
            <p>售后单号：{{ item.subArgueNo }}</p>
            <p>订单编号：{{ item.orderNo }}</p>
            <p>外部单号：{{ item.orderNoOneline }}</p>
            <p>
              平台追差状态：{{
                optionsFilter(item.state, platformStateOptions)
              }}
              <el-link
                type="warning"
                :underline="true"
                @click="
                  openAction(`/difference/process?argueNo=${item.argueNo}`)
                "
                >查看详情</el-link
              >
            </p>
            <p v-if="params.honorState == -1" class="apply_times">
              申请{{ item.applyNum }}次
            </p>
            <!-- <p>
              订单编号：<span :id="'order-no-' + index">{{ item.orderNo }}</span>
              <img style="width: 18px;height: 18px;cursor: pointer;transform: translate(6px,3px)"
                @click="copy('order-no-' + index); $message.success('复制成功')" src="../../../assets/images/fztb.png" alt="">
            </p>
            <p>申请时间：{{ item.applyTime }}</p>
            <p>成交回收商：{{ item.manualMerchantName }}</p>
            <p v-if="item.orderNoOneline !== '--'">外部订单号：{{ item.orderNoOneline }}</p>
            <p v-if="item.channelName !== '--'" class="channel_name">{{ item.channelName }}</p>
            <p v-if="item.orderType === '01' && item.outType === 'fx'" class="matrix_name">矩阵订单</p>
            <p class="apply_times">发起{{ item.orderApplyTimes }}次</p>
            <p v-if="activeIndex === 3" class="apply_times" :class="item.moneyWay === '00' ? 'blue_color' : ''">{{
              item.moneyWay === '00' ? '线下打款' : '线上打款' }}</p> -->
          </div>
          <div class="table_header_right">
            <p class="day_num" style="color: #ff687b">
              已入库{{ item.intoDay }}天
            </p>
            <!-- <p class="day_num" style="color: #FF687B;" v-if="params.state === '04'">差异共处理{{ item.handleDay }}天</p>
            <p class="day_num" style="color: #FF687B;" v-if="item.applyAprDays > 0 && params.state !== '04'">
              已发起差异{{ item.applyAprDays }}天，请尽快处理</p> -->
          </div>
        </div>
        <div>
          <el-table
            v-if="tableData.data.length > 0"
            ref="tableName"
            size="small"
            :header-cell-style="{ padding: 0, backgroundColor: '#F9FBFD' }"
            :data="[item]"
          >
            <template v-for="item in tableColumns">
              <el-table-column
                :prop="item.prop"
                :key="item.prop + item.state"
                align="center"
                :label="item.label"
                :width="item.width"
                :class-name="
                  item.prop === 'action' ? 'action_style' : 'current_row_style'
                "
                :fixed="item.prop === 'action' ? 'right' : null"
                v-if="!item.isHide"
              >
                <template v-slot="{ row }">
                  <!-- 旧机照片 -->
                  <div v-if="item.prop === 'images'" class="photo_list">
                    <img :src="row[item.prop][0].smallImg" alt="" />
                    <p @click="voucherView(row[item.prop])" class="lv_fc_line">
                      共{{ row[item.prop].length }}张
                    </p>
                  </div>
                  <!-- 串号 -->
                  <div v-else-if="item.prop === 'imei'">
                    <p>{{ row[item.prop] }}</p>
                    <!-- getFunction(row.orderNo, row.outType) -->
                    <p
                      class="lv_fc_line"
                      @click="$refs['functionDia'].open(row)"
                    >
                      功能情况
                    </p>
                  </div>
                  <!-- 提交人 -->
                  <div v-else-if="item.prop === 'applyAdminAcc'">
                    {{ row.applyAdminName }}-{{ row.applyAdminAcc }}
                  </div>
                  <!-- 售后类型 -->
                  <div v-else-if="item.prop === 'argueType'">
                    {{ optionsFilter(row[item.prop], argueTypeOption) }}
                  </div>
                  <!-- 修改记录 -->
                  <div v-else-if="item.prop === 'updateRecord'">
                    <div
                      class="remarkBtn"
                      @click="$refs['changeApplyRecordDia'].open(row)"
                    >
                      {{ row[item.prop] === "--" ? 0 : row[item.prop] }}
                    </div>
                  </div>
                  <!-- 支付流水号 -->
                  <div v-else-if="item.prop === 'payIdList'">
                    <div v-if="row[item.prop] !== '--'">
                      <div
                      v-for="(itenm, index) in row.payIdList"
                      :key="index"
                    >
                      {{ itenm }}{{ row.payIdList.length>1&&itenm?'，':'' }}
                    </div>
                    </div>
                    <div
                      class="lv_fc_line"
                      @click="serchPaycode(row)"
                      >查询</div
                    >
                  </div>
                  <!-- 寄回地址 -->
                  <div v-else-if="item.prop === 'address'">
                    <div
                      v-if="row[item.prop] !== '--'"
                      class="remarkBtn"
                      @click="$refs['addressInfoDia'].open(row)"
                    >
                      查看寄回地址
                    </div>
                    <div v-else>{{ row[item.prop] }}</div>
                  </div>
                  <!-- 寄回单号 -->
                  <div v-else-if="item.prop === 'expressNo'">
                    {{ row[item.prop] }}
                    <div
                      v-if="row[item.prop] !== '--'"
                      class="remarkBtn"
                      @click="$refs['expressNoDia'].open(row)"
                    >
                      修改
                    </div>
                  </div>
                  <!-- 提交人 -->
                  <div v-else-if="item.prop === 'revocationAdminAcc'">
                    {{ row.revocationAdminName }}-{{ row.revocationAdminAcc }}
                  </div>
                  <!-- 操作 -->
                  <div
                    v-else-if="item.prop === 'action'"
                    class="action_box"
                    :key="item.state"
                  >
                    <el-link
                      type="primary"
                      @click="$refs['reviewApplyDia'].open(row, 'detail')"
                      >外部售后申请详情</el-link
                    >
                    <el-link
                      v-if="[0, 1].includes(params.honorState)"
                      type="danger"
                      @click="revocation(row)"
                      >撤回售后</el-link
                    >
                    <el-link
                      v-if="[0].includes(params.honorState)"
                      type="warning"
                      @click="$refs['changeApplyDia'].open(row)"
                      >修改申请信息</el-link
                    >
                    <el-link
                      v-if="
                        [2].includes(params.honorState) && row.expressNo == '--'
                      "
                      type="danger"
                      @click="$refs['expressNoDia'].open(row)"
                      >添加快递单号</el-link
                    >
                    <el-link
                      v-if="[-2].includes(params.honorState)"
                      type="danger"
                      @click="$refs['reviewApplyDia'].open(row, 'create')"
                      >重新申请售后</el-link
                    >
                  </div>
                  <div v-else>
                    {{ row[item.prop] }}
                  </div>
                </template>
              </el-table-column>
            </template>
          </el-table>
        </div>
      </div>
      <Pagination
        :meta="meta"
        @sizeChange="
          (v) => {
            meta = { ...meta, pageSize: v };
            params = { ...params, pageSize: v };
          }
        "
        @pageChange="
          (v) => {
            meta = { ...meta, pageNum: v };
            params = { ...params, pageNum: v };
          }
        "
      />
    </div>
    <PhotoPreview
      :photoVisible="photoDialogProp.show"
      :photoArr="photoDialogProp.photoArr"
      title="旧机照片"
      @close="() => (photoDialogProp.show = false)"
    />
    <ReviewApplyDia ref="reviewApplyDia" />
    <ChangeApplyDia ref="changeApplyDia" @confirm="getData()" />
    <ChangeApplyRecordDia ref="changeApplyRecordDia" />
    <FunctionDia ref="functionDia" />
    <ExpressNoDia ref="expressNoDia" @confirm="getData()" />
    <AddressInfoDia ref="addressInfoDia" />
  </div>
</template>
<script>
import {
  formItemList,
  photoDialogProp,
  platformTableColumns,
  rejectMarkOption,
  rejectProp,
  tabData,
  tableData,
  argueTypeOption,
  platformStateOptions,
} from "./initState";
import _api from "@/utils/request";
import moment from "moment";
import {
  copy,
  getHaoTime,
  getIndex,
  getMeta,
  getRecoveryType,
  getRecycleState,
  getStoreRole,
  getStoreRoleTwo,
  guideExcelAction,
  infoConcat,
  openAction,
  pageSize,
  setValue,
} from "@/utils/common";
import PhotoPreview from "@/components/common/PhotoPreview.vue";
import TabSelect from "@/components/common/TabSelect.vue";
import GlobalForm from "@/components/global/components/GlobalForm.vue";
import Pagination from "@/components/common/Pagination.vue";
import { mapState } from "vuex";
// ***********
import DialogAction from "@/components/common/DialogAction.vue";
import { imeiTestProps } from "@/views/RecallOrder/initState";
import TestDeviceInfo from "@/views/RecallOrder/componets/TestDeviceInfo.vue";
import Title from "@/views/RecallOrder/componets/Title.vue";
// import TestTable from "@/views/RecallOrder/componets/TestTable.vue";
import JudgementsList from "@/views/difference/componets/JudgementsList.vue";
import Remark from "@/views/RecallOrder/componets/Remark.vue";
import { encryptByDES } from "@/utils/3DES";
import PasteUploadDia from "../componets/PasteUploadDia.vue";
import ReviewApplyDia from "../componets/ReviewApplyDia.vue";
import ChangeApplyDia from "../componets/ChangeApplyDia.vue";
import ChangeApplyRecordDia from "../componets/ChangeApplyRecordDia.vue";
import { confirm } from "@/components/common/messagebox";
import FunctionDia from "../componets/FunctionDia.vue";
import ExpressNoDia from "../componets/ExpressNoDia.vue";
import AddressInfoDia from "../componets/AddressInfoDia.vue";
export default {
  components: {
    // JudgementsList,
    // DialogAction,
    Pagination,
    GlobalForm,
    TabSelect,
    PhotoPreview,
    // TestTable,
    // TestDeviceInfo,
    // Title,
    // Remark,
    // PasteUploadDia,
    ReviewApplyDia,
    ChangeApplyDia,
    ChangeApplyRecordDia,
    FunctionDia,
    ExpressNoDia,
    AddressInfoDia,
  },
  computed: {
    adminButton() {
      return this.$store.state.tagsView.adminButton;
    },
  },

  data() {
    const startTime = moment()
      .subtract(30, "day")
      .startOf("day")
      .format("YYYY-MM-DD");
    const endTime = moment().endOf("day").format("YYYY-MM-DD");
    const time = [startTime, endTime];
    const { orderNo } = this.$route.query; //订单详情跳转过来携带订单号,//差异详情跳转过来携带state
    console.log(tabData);
    return {
      baoPricestate: "", //订单状态
      moneyUseTypeOption: [
        //追差款使用
        {
          label: "仅使用差异基金",
          value: "00",
        },
        {
          label: "仅门店打款",
          value: "01",
        },
        {
          label: "差异基金+门店打款",
          value: "02",
        },
      ],
      moneyUseTypeList: [],
      argueTypeOption,
      platformStateOptions,
      // ***************************
      headers: {
        ACCESS_TOKEN: localStorage.getItem("token"),
        channel: "platformPc",
        TOKEN: "",
      },
      // 导入线下打款订单
      importForm: {
        fileName: "",
      },
      importRules: {
        fileName: [
          {
            required: true,
            message: "仅支持.xls及.xlsx格式,单次上传大小不超过5M",
            trigger: "blur",
          },
        ],
      },
      paramsExcel: {},
      importList: [],
      isImported: false, //控制是否上传了数据
      importVisible: false,
      // 备注************
      addRemarkShow: false,
      remarkForm: {
        note: "",
        images: [],
      },
      remarkRules: {
        note: [
          { required: true, message: "请输入备注内容", trigger: "change" },
        ],
      },

      RemarksTxt: "", //备注内容
      RemarksImages: [], //备注内容
      // 备注记录
      argueNo: "",
      remarkRecords: false,
      DialogTableColumns: [
        { label: "备注时间", prop: "staffPhone" },
        { slotName: "staffName" }, //备注人
        { label: "备注内容", prop: "staffPhone" },
        { slotName: "staffName" }, //备注环节
      ],
      DialogDataList: [], //列表数据
      reTotal: 0,
      rePageNum: 1,
      rePageSize: 10,
      // ****************
      externalorderType: "", //订单类型
      tabActive: 0, //tab
      staAdminList: [], //暂存人列表
      startDiffFund: "", //	开始申请追差金额
      endDiffFund: "", //	结束申请追差金额
      catalogType: "",
      catalogContent: "",
      catalogList: [], //追差类型
      cataloginfoList: [], //追差内容
      ifsureSelect: true, //是否选中追差类型
      // 功能情况接口
      detailData: {
        functionView: {
          normalList: [],
          errorList: [],
        },
        basicFunctionList: [],
        plugFunctionList: [],
        informationList: [],
      },
      activeIndex: 0, //tab初始选择索引
      imeiTestProps, //功能情况
      tabData,
      tableData,
      // 嵌套过深，element无法检测到
      tableColumns: JSON.parse(JSON.stringify(platformTableColumns)),
      initData: {
        createTime: time, //申请时间默认62天
        // auditTime: time,
        // closeTime: time,
        orderType: null,
        outOrder: null,
        orderNo,
      },
      // 分页数据
      meta: {
        total: 0,
        pageSize,
        pageNum: 1,
      },
      // 搜索参
      params: {
        pageSize,
        pageNum: 1,
        honorState: 0,
        orderNo,
        createTime: time, //申请时间默认62天
        // auditTime: time,
        // closeTime: time,
      },
      // 搜索列表
      formItemList,
      // 旧机照片弹窗
      photoDialogProp,
      recyclerData: {
        show: false,
        storeList: [],
        merchantName: "",
      },
      // 驳回差异
      rejectProp,
      //差异单数超5天
      // num: 0,
      excelLoading: false,
      viewrecords: false,
      getUpdatedQuoteLog: [],
      remakeQuoteLog: [],
    };
  },
  created() {
    // 下拉
    this.getSelectList();
    this.getCatalogList();
    // 列表
    //差异详情跳转过来携带state
    // const { activeIndex } = this.$route.query;
    // if (activeIndex) {
    //   this.activeIndex = 5
    // } else {
    this.activeIndex = 0;
    this.getData(0);
    // }
    // this.getDataNum();
  },
  watch: {
    params(v) {
      this.getData();
    },
    catalogType(data) {
      console.log(data);
      if (data) {
        this.ifsureSelect = false;
        this.getcatalogInfo(data);
      } else {
        this.ifsureSelect = true;
      }
      this.catalogContent = "";
      this.cataloginfoList = [];
    },
    startDiffFund: {
      handler(onl, newew) {
        console.log(onl);
        this.startDiffFund = onl.replace(/^(0+)|[^\d]+/g, "");
      },
    },
    endDiffFund: {
      handler(onl, newew) {
        console.log(onl);
        this.endDiffFund = onl.replace(/^(0+)|[^\d]+/g, "");
      },
    },
  },
  methods: {
    serchPaycode(val) {
      console.log(val);
      _api
        .getquerypayId({
          subArgueNo: val.subArgueNo,
        })
        .then((res) => {
          this.$message({
            type: "success",
            message: "查询成功！",
          });
          this.getData(0);
        });
    },
    changeSelect(val) {
      console.log(val);
      if (!val || !val.length) {
        this.moneyUseTypeList = [];
        return;
      }
      this.moneyUseTypeList = val;
      console.log(this.moneyUseTypeList, "val333333333333");
    },
    // ************
    // 导入线下打款订单
    download() {
      const link = document.createElement("a");
      link.href = "/static/导入待复核打款订单数据模板.xlsx";
      link.setAttribute("download", "导入待复核打款订单数据模板.xlsx");
      document.body.appendChild(link);
      link.click();
    },
    // 上传文件
    onUploadList(event, type) {
      console.log(event);
      this.paramsExcel.file = event.target.files;
      if (this.paramsExcel.file.length) {
        this.paramsExcel.excelName = event.target.files[0].name;
        this.importForm.fileName = event.target.files[0].name;
      }
      this.uploadFile();
    },
    // 确定导出
    uploadFile() {
      if (!this.paramsExcel.file || this.paramsExcel.file.length == 0) {
        return this.$message.error("请上传表格");
      }
      const fd = new FormData();
      console.log(this.paramsExcel);
      fd.append("file", this.paramsExcel.file[0]); //文件
      // fd.append("supplierId", this.supplierId); //出库商家id
      // fd.append("warehouseId", this.warehouseId); //仓库id
      console.log(fd);
      this.repeatData = [];
      // 发起请求
      _api
        .aprImport(fd)
        .then((res) => {
          console.log("_RequestUploads_", res);
          this.importList = res.data;
          this.isImported = true;
          console.log(this.importList, "4444444444444444444");
          if (res.code === 1) {
            this.importForm.fileName = "";
            this.$refs.fileUpload.value = "";
            let erraction = JSON.parse(JSON.stringify(res));
            let erractioncopy = JSON.parse(JSON.stringify(res));
            this.seachDataList.unshift(...res.data);
            this.$message({
              type: "success",
              message: "导入成功！",
            });
          } else {
            this.$message({
              type: "warning",
              message: res.msg,
            });
          }
        })
        .catch((err) => {
          console.log(err);
          this.importForm.fileName = "";
          this.$refs.fileUpload.value = "";
        });
    },
    fileClear() {
      this.$refs.fileUpload.value = "";
      this.paramsExcel = {};
    },
    importSuccess() {
      _api.importSave({ list: this.importList }).then((res) => {
        if (res.code === 1) {
          this.importVisible = false;
          this.importList = [];
          this.isImported = false;
          this.$message({
            type: "success",
            message: res.msg || "操作成功",
          });
        }
      });
    },
    exportinDialogClosed() {
      this.importVisible = false;
      this.importList = [];
      this.isImported = false;
    },
    revocation(row) {
      confirm({
        title: "提示",
        message: "是否确认撤回售后？",
        confirm() {
          _api
            .aprHonorRevocation({ subArgueNo: row.subArgueNo })
            .then((res) => {
              this.$message.success("操作成功！");
              this.getData();
            });
        },
      });
    },
    // 备注*************
    handleRemarkBtn(row, type) {
      // console.log(row, 'row33333333333');
      // 添加备注
      this.argueNo = row.argueNo;
      if (type === "add") {
        this.remarkForm = {
          note: "",
          images: [],
        };
        this.$refs["remarkFormRef"]?.resetFields();
        this.$nextTick(() => {
          this.addRemarkShow = true;
        });
      } else {
        this.remarkRecords = true;
        this.handleCurrentChangeDialog();
      }
    },
    // 添加备注--取消
    Remarks_log_cl(row) {
      this.addRemarkShow = false;
      this.RemarksTxt = "";
      this.RemarksImages = [];
    },
    // 添加备注--确定
    Remarks_log_sure(row) {
      this.$refs["remarkFormRef"].validate((vaild) => {
        if (vaild) {
          _api
            .aprAddNote({
              ...this.remarkForm,
              argueNo: this.argueNo,
            })
            .then((res) => {
              this.$message({
                type: "success",
                message: "操作成功!",
              });
              this.addRemarkShow = false;
              this.getData();
            });
        }
      });
    },
    // 备注明细
    handleCurrentChangeDialog() {
      _api.noteRecord({ argueNo: this.argueNo }).then((res) => {
        if (res.code === 1) {
          this.DialogDataList = res.data;
          this.reTotal = res.data.total;
        }
      });
    },
    addNoteImg(image, item) {
      _api
        .aprAddNoteImage({
          argueNo: item.argueNo,
          images: [image],
        })
        .then((res) => {
          this.$message.success("添加成功");
          this.handleCurrentChangeDialog();
        });
    },
    onClose() {
      this.remarkRecords = false;
    },
    // *********************
    copy,
    openAction,
    // 追差分类列表
    getCatalogList() {
      _api.catalogList().then((res) => {
        this.catalogList = res.data || [];
      });
    },
    // 追差分类详情
    getcatalogInfo(id) {
      _api.catalogInfo({ id }).then((res) => {
        this.cataloginfoList = res.data.itemList || [];
      });
    },
    // 差异单数超5天
    getDataNum() {
      _api.sta().then((r) => {
        this.num = r.data.unHandleNum;
      });
    },
    //功能情况设置显示值
    setFunctionType(v) {
      let { isSystem, unableDetectionCause, functionList, isOtherTitileShow } =
        this.imeiTestProps;
      // 其他功能 系统检测||无法检测&&功能项存在&&（不是采货侠或者是采货侠订单且外部渠道为fx）
      if (
        (isSystem || unableDetectionCause) &&
        functionList.length &&
        (!v.isOneline || (v.isOneline && v.outType === "fx"))
      ) {
        isOtherTitileShow = true;
      } else {
        isOtherTitileShow = false;
      }
      this.imeiTestProps.isOtherTitileShow = isOtherTitileShow;
      // 02不是采货侠或者是采货侠订单且外部渠道为fx
      if (!v.isOneline || (v.isOneline && v.outType === "fx")) {
        return (this.imeiTestProps.type = "02");
      }
    },

    // 上述02类型  外部渠道盛达lb或者lb2，屏蔽正常开机选项
    setFunctionData(v) {
      const fineness = v.fineness ?? { fineness: [] };
      v.detectionInfo = v.detectionInfo ?? {};
      let {
        isFinenessShow,
        functionList,
        systemTestList,
        photoArr,
        allSystemTestList,
      } = this.imeiTestProps;
      const { cameraTestImgFront, cameraTestImgRear } = v.detectionInfo;
      systemTestList = JSON.parse(JSON.stringify(allSystemTestList));
      let finenessData = Array.isArray(fineness.fineness)
        ? fineness.fineness
        : [];
      functionList = Array.isArray(functionList) ? functionList : [];
      // 盛达外部去除是否开机
      if (v.outType === "lb" || v.outType === "lb2") {
        functionList = functionList.filter(
          (v) => v.faultName !== "是否正常开机"
        );
      }
      // 成色渲染 是采货侠 && 并且存在成色数据
      if (v.isOneline && fineness && finenessData.length) {
        isFinenessShow = true;
        finenessData = Array.isArray(finenessData) ? finenessData : [];
        finenessData = finenessData.map((item) => {
          if (v.outType === "lb" || v.outType === "lb2") {
            item.value = item.value ?? [];
            item.value = item.value.map((v) => {
              return {
                valueText: v.isNormal ? "正常" : "异常",
                isNormal: v.isNormal,
              };
            });
          }
          return {
            name: item.label,
            value: item.value,
          };
        });
      } else {
        isFinenessShow = false;
      }
      // 设置功能机
      functionList = functionList.map((v) => {
        return {
          name: v.faultName,
          state: v.faultAnswer,
          reason: v.faultCause,
        };
      });
      // 检测类型动态更改值的key
      const systemKeys = ["mobileNetworks", "wlan", "bluetooth"];
      if (v.detectionInfo) {
        // 设置系统验机项
        systemTestList = systemTestList.map((item) => {
          item.value = v.detectionInfo[item.key] ?? "--";
          //前三个，正常的就是系统验机，异常的话是人工验机
          if (systemKeys.indexOf(item.key) >= 0) {
            if (v.detectionInfo[item.key] === "01") {
              item.isSystem = true;
            } else {
              item.isSystem = false;
            }
          }
          // 存在值才进行替换
          if (v.detectionInfo[item.tipKey]) {
            item.tips = v.detectionInfo[item.tipKey].replaceAll("&", "       ");
          }
          return item;
        });
      }

      // 设置前置旧机照片
      if (cameraTestImgFront) {
        photoArr.push(cameraTestImgFront);
      }
      // 设置后置
      if (cameraTestImgRear) {
        photoArr.push(cameraTestImgRear);
      }
      this.imeiTestProps = {
        ...this.imeiTestProps,
        functionList,
        isFinenessShow,
        finenessData,
        systemTestList,
        photoArr,
      };
    },
    // 功能情况接口
    getDetail(orderNo) {
      _api.getInspectionReport({ orderNo }).then((res) => {
        this.detailData = res.data;
        this.imeiTestProps.isLoadding = false;
      });
    },
    // 功能情况
    getFunction(v, outType) {
      this.externalorderType = outType;
      // 显示弹窗
      this.imeiTestProps = {
        ...this.imeiTestProps,
        phoneName: "",
        // 检测项
        tableData: [],
        // 功能情况
        functionList: [],
        // 系统检测结果
        systemTestResult: [],
        // 是否无法检测
        unableDetectionCause: "",
        // 是否系统检测且存在检测结果
        isSystem: false,
        // 其他功能标题显示，原需求模糊，抄详情写
        isOtherTitileShow: false,
        // 其他功能表格 原需求模糊，抄详情写
        isOtherTableShow: false,
        // 是否显示成色
        isFinenessShow: false,
        // 成色数据
        finenessData: [],
        // 旧机照片
        photoArr: [],
        // 系统验机项
        systemTestList: [],
        orderMode: "", //下单模式
        funcDetail: {},
        show: true,
        isLoadding: true,
      };
      // 请求详情
      this.getDetail(v);
    },
    getStoreRole,
    //设置时间戳
    setParamsTIme(params, key, startKey, endKey) {
      return {
        ...params,
        [startKey]: getHaoTime(params[key][0], "start"),
        [endKey]: getHaoTime(params[key][1], "end"),
        [key]: null,
      };
    },
    // time时间是否重置
    resetTime(params, key, startKey, endKey) {
      if (Array.isArray(params[key])) {
        params = this.setParamsTIme(params, key, startKey, endKey);
      } else {
        params[key] = null;
        params[startKey] = null;
        params[endKey] = null;
      }
      return params;
    },
    // 各种状态的数量设置
    setStateNum(data) {
      this.tabData = this.tabData.map((v) => {
        if (data[v.key] >= 1000) {
          v.num = data[v.key] / 1000 + "k";
        } else {
          v.num = data[v.key] || 0;
        }
        return v;
      });
    },
    getData(honorState) {
      this.tableData.loading = true;
      this.tableData.data = [];
      this.params.moneyUseTypeList = this.moneyUseTypeList;
      let params = this.params;
      params = this.resetTime(
        params,
        "intoTime",
        "intoStartTime",
        "intoEndTime"
      );
      params = this.resetTime(
        params,
        "createTime",
        "createStartTime",
        "createEndTime"
      );
      params = this.resetTime(
        params,
        "checkPassTime",
        "checkPassStartTime",
        "checkPassEndTime"
      );
      params = this.resetTime(
        params,
        "finishTime",
        "finishStartTime",
        "finishEndTime"
      );
      params = this.resetTime(
        params,
        "rejectTime",
        "rejectStartTime",
        "rejectEndTime"
      );
      params = this.resetTime(
        params,
        "cancellationTime",
        "cancellationStartTime",
        "cancellationEndTime"
      );
      if (honorState) {
        params.honorState = honorState;
      }
      _api.aprHonorList(params).then((r) => {
        this.tableData.loading = false;
        const data = r.data ?? {};
        const page = data.page ?? {};
        this.setStateNum(data);
        const records = Array.isArray(page.records) ? page.records : [];
        // 分页
        this.meta = getMeta(page.total, params.pageNum, params.pageSize);
        this.tableData.data = records.map((v, index) => {
          // 下单人
          v.createStaffInfo = infoConcat(v.createStaffName, v.createStaffPhone);
          // 申请人
          v.applyStaffInfo = infoConcat(v.applyStaffName, v.applyStaffMobile);
          // 初审人
          v.auditFirstInfo = infoConcat(
            v.auditFirstAdminName,
            v.auditFirstAdminAcc
          );
          //复核人
          v.auditInfo = infoConcat(v.auditReAdminName, v.auditReAdminAcc);
          // 撤回人
          v.cancelStaffInfo = infoConcat(v.cancelStaffName, v.cancelStaffPhone);

          //追差关闭的几种情况
          // 平台审核
          if (["00", "01", "02"].indexOf(v.rejectMark) >= 0) {
            //驳回
            v.closeInfo = infoConcat(v.rejectAdminName, v.rejectAdminAcc);
            v.closeActionTime = v.rejectTime;
          } else if (v.rejectMark === "03") {
            // 门店驳回
            v.closeInfo = infoConcat(v.rejectStaffName, v.rejectStaffMobile);
            v.closeActionTime = v.rejectTime;
            // 是否创建人
            if (v.rejectStaffIsMajor) {
              v.closeRoleType = "创建人";
            } else {
              // 子账号、店长、店员等角色名获取
              v.closeRoleType = this.getStoreRole(v.rejectStaffType);
            }
          } else if (v.rejectMark === "04") {
            // 撤回
            v.closeInfo = infoConcat(
              v.cancelStaffName || v.cancelPcStaffName,
              v.cancelStaffPhone || v.cancelPcStaffPhone
            );
            v.closeActionTime = v.cancelTime;
            v.closeRoleType = "";
            if (v.cancelStaffIsMajor) {
              v.closeRoleType = "创建人";
            } else {
              v.closeRoleType = getRecoveryType(v.cancelStaffType);
            }
          } else {
            v.closeInfo = "--";
            v.closeActionTime = "--";
          }
          const rejectMarkIndex = rejectMarkOption.findIndex(
            (item) => item.value === v.rejectMark
          );
          if (rejectMarkIndex >= 0) {
            v.rejectMarkText = rejectMarkOption[rejectMarkIndex].label;
          } else {
            v.rejectMarkText = "--";
          }
          v = setValue(v);
          const currentIndex = getIndex(params.pageNum, params.pageSize, index);
          return {
            ...v,
            index: currentIndex >= 10 ? currentIndex : "0" + currentIndex,
            photoList: v.images.map((v) => v.img),
            orderState: getRecycleState(v.orderState),
          };
        });
      }).finally(() => {
        if (typeof this.params.disuseLoding == "function") {
          this.params.disuseLoding();
        }
      })
    },
    getSelectList() {
      const data = {
        pageNum: 1,
        pageSize: 9999,
      };
      // 回收商rq
      _api.listSimpleMerchantView(data).then((r) => {
        const index = this.formItemList.findIndex(
          (v) => v.key === "merchantId"
        );
        const data = Array.isArray(r.data) ? r.data : [];
        this.formItemList[index].option = data.map((v) => {
          return {
            label: v.merchantName,
            value: v.merchantId,
          };
        });
      });
      // 询价商
      _api.getSelectList().then((r) => {
        const index = this.formItemList.findIndex((v) => v.key === "companyId");
        const data = Array.isArray(r.data) ? r.data : [];
        this.formItemList[index].option = data.map((v) => {
          return {
            label: v.name,
            value: v.id,
          };
        });
        // 矩阵原商家
        // const index2 = this.formItemList.findIndex(v => v.key === 'oriCompanyId');
        // const data2 = Array.isArray(r.data) ? r.data : [];
        // this.formItemList[index2].option = data2.map(v => {
        //   return {
        //     label: v.name,
        //     value: v.id
        //   }
        // })
      });
      // 撮合渠道下拉
      // _api.getMatchChannelSelect().then(r => {
      //   const index = this.formItemList.findIndex(v => v.key === 'channelId');
      //   const data = Array.isArray(r.data) ? r.data : [];
      //   this.formItemList[index].option = data.map(v => {
      //     return {
      //       value: v.id,
      //       label: v.name
      //     }
      //   })
      // });
      // 人下拉
      // _api.StaffManageList({ pageNum: 1, pageSize: 99999 }).then(r => {
      //   const auditFirstAdminIndex = this.formItemList.findIndex(v => v.key === 'auditFirstAdminId');
      //   const auditReAdminIndex = this.formItemList.findIndex(v => v.key === 'auditReAdminId');
      //   const data = Array.isArray(r.data.records) ? r.data.records : [];
      //   const result = data.map(v => {
      //     return {
      //       value: v.id,
      //       label: v.userName
      //     }
      //   });
      //   this.formItemList[auditFirstAdminIndex].option = result;
      //   this.formItemList[auditReAdminIndex].option = result;
      //   this.staAdminList = result
      // })
    },
    // 门店下拉
    getShopList(companyId) {
      _api.getStoreSelectList({ companyId }).then((r) => {
        const data = r.data || [];
        const index = this.formItemList.findIndex((v) => v.key === "storeId");
        this.formItemList[index].option = data.map((v) => {
          return {
            label: v.value,
            value: v.key,
          };
        });
      });
    },
    // 搜索变化
    handleChange(v1, v2) {
      if (v1.key === "companyId") {
        const index = this.formItemList.findIndex(
          (v) => v.key === v1.childrenKey
        );
        if (v2) {
          this.formItemList[index].disabled = false;
          this.getShopList(v2);
        } else {
          this.formItemList[index].disabled = true;
        }
      }
    },
    // 搜索确定
    handleConfirm(v,cd) {
      console.log(this.staAdminList, '666666666666');
      console.log(v, '搜索参数1111');
      if (Number(this.startDiffFund) < 0) {
        this.startDiffFund = 0;
        return this.$message.error("开始申请追差金额不能小于0");
      }
      if (Number(this.endDiffFund) < 0) {
        return this.$message.error("结束申请追差金额不能小于0");
      }
      if (Number(this.startDiffFund) > Number(this.endDiffFund)) {
        return this.$message.error("申请追差金额范围不正确");
      }
      this.params.startDiffFund = this.startDiffFund;
      this.params.endDiffFund = this.endDiffFund;
      this.params.catalogId = this.catalogType;
      this.params.itemId = this.catalogContent;
      this.params = {
        ...this.params,
        ...v,
        pageNum: 1,
        disuseLoding: () => {
          if(typeof cd ==='function'){
             cd();
          }
        },
      }
    },
    // 根据状态设置列/和查询条件项
    setStateColumns(v) {
      this.tabActive = v;
      const tableColumns = JSON.parse(JSON.stringify(platformTableColumns));
      let activeIndex = 0,
        showColums = [];
      switch (v) {
        case 0:
          // 待审核
          activeIndex = 0;
          showColums = [];
          break;
        case 1:
          // 审核通过
          activeIndex = 1;
          showColums = ["checkPassTime", "amount"];
          break;
        case 2:
          // 售后完成
          activeIndex = 2;
          showColums = [
            "checkPassTime",
            "amount",
            "finishAmount",
            "payIdList",
            "address",
            "expressNo",
          ];
          break;
        case -2:
          // 售后驳回
          activeIndex = 3;
          showColums = ["rejectTime", "rejectReasons"];
          break;
        case -1:
          // 售后作废
          activeIndex = 4;
          showColums = [
            "revocationAdminAcc",
            "revocationTime",
            "cancellationTime",
          ];
          break;
      }
      this.activeIndex = activeIndex;
      tableColumns.forEach((item) => {
        if (showColums.includes(item.prop)) item.isHide = false;
      });
      this.tableColumns = tableColumns;
    },
    // 状态操作
    tabAction(v) {
      console.log(this.tabData[v]);
      const honorState = this.tabData[v].value;
      this.params = {
        ...this.params,
        pageNum: 1,
        honorState,
      };
      this.setStateColumns(honorState);
    },
    // 上传图片后，将图片信息保存到对应参数中
    handleUploadChange(url) {
      this.RemarksImages = url;
    },
    // 照片预览
    voucherView(images) {
      this.photoDialogProp = {
        ...this.photoDialogProp,
        show: true,
        photoArr: images.map((item) => item.img),
      };
    },
    //驳回差异
    rejectClick(v) {
      // 不存在驳回权限
      const pathIndex = this.adminButton.findIndex(
        (v) => v.pageUrl === "/difference/process"
      );
      const { buttonList } = this.adminButton[pathIndex];
      const i = buttonList.findIndex((v) => v.buttonCode === "Apr_Reject");
      if (i < 0) {
        return this.$message.error("当前无驳回操作权限");
      }
      this.rejectProp = {
        ...this.rejectProp,
        show: true,
        judgements: [],
        currentData: v,
        isReset: true,
      };
    },

    // 依据变化
    rejectChange(v) {
      this.rejectProp.judgements = v.map((v) => {
        return {
          ...v,
          judgeType: "02",
        };
      });
    },

    // 驳回确定
    rejectConfig() {
      const { judgements, currentData } = this.rejectProp;
      const { argueNo } = currentData;
      const flag = judgements.every((v) => v.reason && v.url);
      if (!flag || !judgements.length) {
        return this.$message.error("请填写完整追责依据信息");
      }
      _api.aprRejecy({ argueNo, judgements }).then((r) => {
        this.$message.success("操作成功");
        this.getData();
        this.rejectProp.show = false;
        this.rejectProp.isReset = false;
      });
    },

    // 以下是复制代码
    rowStyle({ row, column, rowIndex, columnIndex }) {
      if (columnIndex == 8 && !(row.quoteNum == 0) && !(row.quoteNum == "--")) {
        return "text-decoration:underline;color:#21a4f1;cursor:pointer;";
      }
    },
    Bargain(row, column, cell, event) {
      if (
        !(row.quoteNum == 0) &&
        !(row.quoteNum == "--") &&
        column.property == "quoteNum"
      ) {
        // this.storeShow = false;
        this.viewrecords = true;
        _api
          .getUpdatedQuoteLog({
            merchantId: row.recycleMerchantId,
            orderNo: row.orderNo,
          })
          .then((res) => {
            this.getUpdatedQuoteLog = res.data.normal;
            this.remakeQuoteLog = res.data.remake;
          });
      }
    },
    viewrecordsClose() {
      this.viewrecords = false;
    },
    // 报价商家弹窗
    openStoreList(e) {
      this.recyclerData.merchantName = e.manualMerchantName;
      this.baoPricestate = e.state;
      _api.orderStoreList({ orderNo: e.orderNo }).then((res) => {
        if (res.code == 1) {
          this.recyclerData.storeList = res.data.map((v) => setValue(v));
        }
      });
      this.recyclerData.show = true;
    },

    //导表
    excelClick() {
      let params = this.params;
      params = this.resetTime(
        params,
        "intoTime",
        "intoStartTime",
        "intoEndTime"
      );
      params = this.resetTime(
        params,
        "createTime",
        "createStartTime",
        "createEndTime"
      );
      params = this.resetTime(
        params,
        "checkPassTime",
        "checkPassStartTime",
        "checkPassEndTime"
      );
      params = this.resetTime(
        params,
        "finishTime",
        "finishStartTime",
        "finishEndTime"
      );
      params = this.resetTime(
        params,
        "rejectTime",
        "rejectStartTime",
        "rejectEndTime"
      );
      params = this.resetTime(
        params,
        "cancellationTime",
        "cancellationStartTime",
        "cancellationEndTime"
      );
      this.excelLoading = true;
      _api.aprHonorExcel(params).then((r) => {
        guideExcelAction(r, "荣耀差异订单", () => {
          this.excelLoading = false;
        });
      });
    },
    optionsFilter(v, options) {
      return options.find((item) => item.value == v)?.label;
    },
  },
};
</script>
<style scoped lang="scss">
.fileName {
  /deep/.el-input {
    width: 260px;
  }

  /deep/.el-button {
    // border-radius: 10px;
    margin-right: 0px;
  }
}

.impor_bottom {
  // display: flex;
  // align-items: center;
  // justify-content: space-between;
  margin-bottom: 30px;

  .inquery {
    color: #4c84ff;
    position: relative;
    padding-left: 20px;
  }

  .inquery::before {
    content: "";
    display: block;
    width: 6px;
    height: 6px;
    background: #4c84ff;
    border-radius: 50%;
    position: absolute;
    left: 10px;
    top: 50%;
    transform: translateY(-50%);
  }
}
.jus_spa {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.jus_end {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.import_box {
  margin-top: 20px;
  display: flex;
  align-items: center;
}

.impor_tip {
  color: #ff5c5c;
  font-size: 14px;
  margin-top: 20px;
  margin-bottom: 40px;
  padding-left: 64px;
}

.impor_inp {
  width: 200px;
  height: 34px;
  border: 1px solid #c1d1ff;
  background-color: #f9fbfd;
  border-radius: 6px;
}

.cance_tip {
  color: #ff8080;
  font-size: 14px;
  margin-bottom: 20px;
}

.remarkBtn {
  color: #0981ff;
  cursor: pointer;
  text-decoration: underline;
}

.function_box {
  max-height: 700px;
  overflow: auto;
}

.box {
  > .header {
    width: 100%;
    background: #ffffff;
    border-radius: 4px;
    padding: 18px 20px 24px 15px;
    box-sizing: border-box;

    > .title {
      width: 100%;
      display: flex;
      align-items: center;

      > img {
        width: 30px;
        height: 30px;
        margin-right: 9px;
      }

      > h3 {
        font-size: 16px;
        font-weight: 700;
        color: #333333;
        margin-right: 8px;
        transform: translateY(-1px);
      }

      > p {
        font-size: 14px;
        font-weight: 400;
        color: #4c84ff;
      }
    }

    > .search {
      margin-top: 12px;
      width: 100%;
      box-sizing: border-box;
      padding: 15px 14px 0px 14px;
      background: #f5f6fa;
      border-radius: 4px;
    }
  }
}

.box {
  /deep/ .current_row_style .cell {
    line-height: 20px;
    padding: 10px;
  }

  ///deep/ .action_style .cell{
  //  visibility: visible !important;
  //  position: relative;
  //  z-index: 1999;
  //}

  ///deep/ .action_style .action_box{
  //  overflow: hidden;
  //}
  //
  ///deep/ .el-table__body-wrapper .action_style .action_box{
  //  display: none;
  //}

  /deep/ .photo_list img {
    width: 60px;
    height: 60px;
  }

  > .body {
    margin-top: 15px;
    width: 100%;
    border-radius: 4px;
    background: #fff;
    padding: 14px 20px 14px 17px;
    box-sizing: border-box;

    > .table {
      margin-top: 14px;

      > .table_header {
        width: 100%;
        padding: 11px 0;
        padding-right: 10px;
        border-radius: 4px 4px 0px 0px;
        background: #e9f0fe;
        display: flex;
        align-items: center;
        justify-content: space-between;

        > .table_header_left {
          display: flex;
          align-items: center;
          font-size: 14px;
          font-weight: 400;
          color: #4c84ff;
          justify-content: space-between;

          > p {
            margin-right: 30px;
          }

          > .table_index {
            width: 30px;
            height: 18px;
            background: #f5b724;
            border-radius: 3px 9px 9px 3px;
            text-align: center;
            line-height: 18px;
            margin-right: 9px;
            font-size: 14px;
            font-weight: 400;
            color: #ffffff;
          }
        }

        > .table_header_right {
          > .day_num {
            font-size: 12px;
            font-weight: 400;
            color: #ff687b;
          }
        }
      }

      /deep/ .el-table .el-table__header th {
        background: #f9fbfd !important;
        border-width: 0;
        border-bottom-width: 1px;
      }
    }
  }
}

.tab_action {
  display: flex;
  align-items: center;
  justify-content: space-between;

  .taab_left {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .excel_btn {
    margin-left: 20px;
    height: 30px;
    cursor: pointer;
    background: #50cd85;
    border-radius: 4px;
    display: flex;
    font-size: 12px;
    font-weight: 400;
    color: #ffffff;
    align-items: center;
    padding: 0 9px;

    img {
      margin-right: 2px;
      width: 16px;
      height: 16px;
    }
  }
}

.photo_list {
  > img {
    width: 70px;
    height: 70px;
    object-fit: cover;
  }
}

.channel_name {
  padding: 0 11px;
  height: 20px;
  background: #ffffff;
  border: 1px solid #ffaf29;
  border-radius: 10px;
  font-size: 12px;
  font-weight: 400;
  color: #ffaf29;
  line-height: 20px;
}

.matrix_name {
  padding: 0 11px;
  height: 20px;
  background: #ffffff;
  border: 1px solid #21d8c2;
  border-radius: 10px;
  font-size: 12px;
  font-weight: 400;
  color: #21d8c2;
  line-height: 20px;
}

.apply_times {
  padding: 0 11px;
  height: 20px;
  background: #ffffff;
  border: 1px solid #ff5c5c;
  border-radius: 10px;
  font-size: 12px;
  font-weight: 400;
  color: #ff5c5c;
  line-height: 20px;
}

.blue_color {
  border: 1px solid #4c84ff;
  color: #4c84ff;
}

.action_box {
  display: flex;
  align-items: center;
  justify-content: space-around;

  > p {
    flex: 1;
    text-align: center;
  }
}

// 弹窗
.img-box {
  margin-bottom: 20px;

  // 图片
  .imgs-num {
    text-align: center;
    margin: 5px 0;
    font-weight: bold;
  }

  .img-tips-box {
    display: flex;
    height: 30px;
    width: max-content;
    border: 1px solid #0981ff;

    .img-tips {
      width: 80px;
      text-align: center;
      line-height: 30px;
      box-sizing: border-box;
      cursor: pointer;
    }

    .img-tips-default {
      background: #fff;
      color: #0981ff;
    }

    .img-tips-choose {
      background: #0981ff;
      color: #fff;
    }
  }

  // 列表
}

.rejetct_box {
  .reject_duty {
    display: flex;
    align-items: center;
  }

  .reject_basis {
    margin-top: 10px;

    /deep/ .box .list_box p {
      width: 80px;
    }
  }
}

.order_num {
  margin-left: 10px;
  background: #e4ecfd;
  border-radius: 4px;
  padding: 6px;
}

//去除数字框上下箭头
/deep/ input::-webkit-outer-spin-button,
/deep/ input::-webkit-inner-spin-button {
  -webkit-appearance: none !important;
}

/deep/ input[type="number"] {
  -moz-appearance: textfield;
}

///deep/.table_header_style{
//}

.paste-img-box {
  display: flex;
  .img-item {
    position: relative;
    margin-right: 20px;
    .el-icon-circle-close {
      font-size: 30px;
      position: absolute;
      top: -15px;
      right: -15px;
      z-index: 5;
      color: #fd677d;
    }
    .img {
      width: 100px;
      height: 100px;
    }
  }
}
</style>
