<template>
  <div>
    <el-dialog v-loading="loading" title="修改信息" :visible.sync="visible" :close-on-click-modal="false" width="850px">
      <div class="title">修改内容</div>
      <el-form ref="formRef" :model="formData" :rules="formRule">
        <el-row :gutter="20">
          <el-col :span="24">
            <el-form-item label="请选择修改内容：" prop="modifyType">
              <el-radio-group v-model="formData.modifyType">
                <el-radio label="00">修改补差金额</el-radio>
                <el-radio label="01">修改打款信息</el-radio>
              </el-radio-group>
            </el-form-item>
          </el-col>
          <template v-if="formData.modifyType == '00'">
            <el-col :span="12">
              原补差金额：
              <div class="box gray">
                <el-form-item label="追差金额">
                  <el-input v-model="info.arguePrice" readonly style="width:100px" />
                  元
                </el-form-item>
              </div>
            </el-col>
            <el-col :span="12">
              <div class="red">修改后补差金额：</div>
              <div class="box gray">
                <el-form-item label="追差金额" prop="modifyArguePrice">
                  <el-input v-model="formData.modifyArguePrice" style="width:100px" />
                  元
                </el-form-item>
              </div>
            </el-col>
          </template>
          <template  v-if="formData.modifyType == '01'">
            <el-col :span="12">
              原打款信息：
              <div class="box gray">
                <div class="info-item">收款人真实姓名：{{info.payeeName}}</div>
                <div>收款人支付宝账户：{{info.payeeAlipayAccount}}</div>
              </div>
            </el-col>
            <el-col :span="12">
              <div class="red">修改后打款信息：</div>
              <div class="box gray">
                <el-form-item label="真实姓名" label-width="100px" prop="modifyPayeeName">
                  <el-input v-model="formData.modifyPayeeName" placeholder="请输入真实姓名" />
                </el-form-item>
                <el-form-item label="支付宝账号" label-width="100px" prop="modifyPayeeAlipayAccount">
                  <el-input v-model="formData.modifyPayeeAlipayAccount" placeholder="请输入支付宝账号" />
                </el-form-item>
              </div>
            </el-col>
          </template>
        </el-row>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="visible = false">取消</el-button>
        <el-button type="primary" @click.native="confirm">确定</el-button>
      </span>
    </el-dialog>
    <el-dialog title="安全验证" :visible.sync="onelineVisible" :close-on-click-modal="false" width="500px">
      <div class="command">
        <div style="width: 85px;">动态口令：</div>
        <el-input v-model="command" placeholder="请输入谷歌动态口令"
          oninput="this.value = this.value.replace(/[^0-9a-zA-Z]/ig,'')"></el-input>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="onelineVisible = false;">取消</el-button>
        <el-button type="primary"  @click.native="onelineSubmit">确定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import _api from "@/utils/request";
export default {
  name: "ChangeApplyDia",
  data() {
    return {
      visible: false,
      row: {},
      info: {},
      loading: false,
      formData: {
        modifyType: '00',
        modifyArguePrice: '',
        modifyPayeeName: '',
        modifyPayeeAlipayAccount: '',
      },
      formRule: {
        modifyArguePrice: [{ required: true, message: '请输入金额', trigger: 'change' }],
        modifyPayeeName: [{ required: true, message: '请输入真实姓名', trigger: 'change' }],
        modifyPayeeAlipayAccount: [{ required: true, message: '请输入支付宝账号', trigger: 'change' }],
      },

      onelineVisible: false,
      command: ''
    };
  },
  methods: {
    open(row) {
      this.row = row
      this.formData = {
        modifyType: '00',
        modifyArguePrice: '',
        modifyPayeeName: '',
        modifyPayeeAlipayAccount: '',
      }
      this.getArgueDetail()
      this.$nextTick(() => {
        this.$refs['formRef']?.clearValidate()
        this.visible = true
      })
    },
    getArgueDetail() {
      this.loading = true
      const { argueNo, subArgueNo } = this.row
      _api.aprHonorDetail({
        argueNo: argueNo == '--' ? undefined : argueNo,
        subArgueNo: subArgueNo == '--' ? undefined : subArgueNo
      }).then(res => {
        this.info = res.data
      }).finally(() => {
        this.loading = false
      })
    },
    confirm() {
      this.$refs['formRef'].validate((vaild) => {
        if (vaild) {
          // _api.aprHonorModify({
          //   ...this.formData,
          //   subArgueNo: this.row.subArgueNo
          // }).then(() => {
          //   this.$message.success('操作成功')
          //   this.close()
          //   this.$emit('confirm')
          // })
          this.command = ""
          this.onelineVisible = true
        }
      })
    },
    onelineSubmit() {
      _api.aprHonorModify({
        ...this.formData,
        subArgueNo: this.row.subArgueNo,
        command: this.command,
      }).then(() => {
        this.visible = false
        this.onelineVisible = false
        this.$message.success('操作成功')
        this.$emit('confirm')
      })
    },
  }
};
</script>

<style lang="scss" scoped>
.box{
  padding: 10px;
  &.gray {
    background: rgb(245, 246, 250);
  }
}
.title{
  font-weight: bold;
  margin-bottom: 10px;
}
.info-item{
  margin-bottom: 5px;
}
.mr-30{
  margin-right: 30px;
}
.ml-10{
  margin-left: 10px;
}
.red{
  color: #F56C6C;
}
/deep/.el-form-item {
  margin-bottom: 5px;
}
.command {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  justify-content: flex-start;
}
</style>