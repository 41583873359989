export const rejectMarkOption = [
    {
        label: '平台初审驳回',
        value: "00"
    },
    {
        label: '平台复核驳回',
        value: "01"
    },
    {
        label: '门店打款驳回',
        value: "02"
    },
    {
        label: '门店审核驳回',
        value: "03"
    },
    {
        label: '回收商撤回申请',
        value: "04"
    }
]

export const argueTypeOption = [
    { label: '补差', value: 0 },
    { label: '退货退款', value: 1 },
]

export const platformStateOptions = [
    { label: '待平台初审', value: '00' },
    { label: '待复核打款', value: '01' },
    { label: '待门店打款', value: '02' },
    { label: '追差完成', value: '04' },
    { label: '追差驳回', value: '10' },
    { label: '取消追差', value: '20' },
]

export const formItemList = [
    {
        labelName: "订单号",
        key: 'orderNo',
        type: "input",
        lableWidth: "58px",
        placeholder: "请输入查询",
    },
    {
        labelName: "外部订单号",
        key: 'orderNoOneline',
        type: "input",
        lableWidth: "90px",
        placeholder: "请输入查询",
    },
    {
        labelName: "售后单号",
        key: 'subArgueNo',
        type: "input",
        lableWidth: "90px",
        placeholder: "请输入查询",
    },
    {
        labelName: "门店商家",
        key: 'companyId',
        type: "selectFilterable",
        placeholder: "请选择",
        lableWidth: '80px',
        clear: true,
        option: [],
        clearFun: () => false
    },
    {
        labelName: "回收商家",
        key: 'merchantId',
        type: "selectFilterable",
        placeholder: "请选择",
        lableWidth: '80px',
        clear: true,
        option: [],
        clearFun: () => false
    },
    {
        labelName: '售后类型',
        key: 'argueType',
        type: 'select',
        lableWidth: '98px',
        placeholder: "请选择",
        option: argueTypeOption,
    },
    {
        lableWidth: "100px",
        labelName: "外部收货时间",
        key: 'intoTime',
        type: "daterange",
    },
    // 新增成交时间
    {
        lableWidth: "100px",
        labelName: "提交售后时间",
        key: 'createTime',
        type: "daterange",
    },
    // 新加初审时间
    {
        lableWidth: "100px",
        labelName: "审核通过时间",
        key: 'checkPassTime',
        type: "daterange",
    },
    {
        lableWidth: "100px",
        labelName: "售后完成时间",
        key: 'finishTime',
        type: "daterange",
    },
    {
        lableWidth: "100px",
        labelName: "售后驳回时间",
        key: 'rejectTime',
        type: "daterange",
    },
    {
        lableWidth: "100px",
        labelName: "售后作废时间",
        key: 'cancellationTime',
        type: "daterange",
    },
]

export const tabData = [
    {
        text: "待审核",
        value: 0,
        num: 0,
        key: "waitNum",
    },
    {
        text: "审核通过",
        value: 1,
        num: 0,
        key: "passNum",
    },
    {
        text: "售后完成",
        value: 2,
        num: 0,
        key: "finishNum",
    },
    {
        text: "售后驳回",
        value: -2,
        num: 0,
        key: "rejectNum",
    },
    {
        text: "售后作废",
        value: -1,
        num: 0,
        key: "cancellationNum",
    },
]

export const tableData = {
    loading: false,
    // 当前数据
    currentData: {},
    // 表数据
    data: [],
    // 表列
};

// 表列
export const platformTableColumns = [
    {
        prop: "images",
        label: "旧机照片",
    },
    {
        prop: "phoneName",
        label: "机型",
    },
    {
        prop: "imei",
        label: "串号",
    },
    {
        prop: "manualQuotePrice",
        label: "回收商报价",
    },
    {
        prop: "chxPrice",
        label: "外部成交价",
    },
    {
        prop: "applyAdminAcc",
        label: "提交人",
    },
    {
        prop: "createTime",
        label: "提交时间",
    },
    {
        prop: "arguePrice",
        label: "提交金额",
    },
    {
        prop: "argueNote",
        label: "售后原因",
    },
    {
        prop: "argueType",
        label: "售后类型",
    },
    {
        prop: "checkPassTime",
        label: "审核通过时间",
        // 控制是否隐藏当前
        isHide: true,
    },
    {
        prop: "amount",
        label: "补差金额（审核通过）",
        // 控制是否隐藏当前
        isHide: true,
    },
    {
        prop: "finishAmount",
        label: "补差金额（售后完成）",
        // 控制是否隐藏当前
        isHide: true,
    },
    {
        prop: "payIdList",
        label: "支付流水号",
        // 控制是否隐藏当前
        isHide: true,
    },
    {
        prop: "address",
        label: "寄回地址",
        // 控制是否隐藏当前
        isHide: true,
    },
    {
        prop: "expressNo",
        label: "寄回单号",
        // 控制是否隐藏当前
        isHide: true,
    },
    {
        prop: "rejectTime",
        label: "驳回时间",
        // 控制是否隐藏当前
        isHide: true,
    },
    {
        prop: "rejectReasons",
        label: "驳回原因",
        // 控制是否隐藏当前
        isHide: true,
    },
    {
        prop: "revocationAdminAcc",
        label: "撤回人",
        // 控制是否隐藏当前
        isHide: true,
    },
    {
        prop: "revocationTime",
        label: "售后撤回时间",
        // 控制是否隐藏当前
        isHide: true,
    },
    {
        prop: "cancellationTime",
        label: "售后作废时间",
        // 控制是否隐藏当前
        isHide: true,
    },
    {
        prop: "updateRecord",
        label: "修改记录",
    },
    {
        prop: "action",
        width: '300px',
        label: "操作"
    }
]

// 责任列表
export const responsibilityData = [
    {
        label: "门店责任",
        value: "C"
    },
    {
        label: "回收商责任(驳回追差)",
        value: "M"
    },
    {
        label: "双方共同责任",
        value: "T"
    },
]

//责任切换弹窗提示
export const switchResponsibility = {
    title: '切换提示',
    show: false,
    isTip: true,
    tipText: '切换选项会清空已填写的信息，是否确认继续操作？',
    newValue: '',
}


//确认扣款余额设置
export const deBalanceVoucherProps = {
    title: "确认扣款商户余额",
    show: false,
    headerMessage: "说明：请谨慎确认追差款项从商户余额扣款，并上传沟通凭证",
    isView:false//是否只是查看
}

export const photoDialogProp = {
    show: false,
    photoArr: []
}


// 驳回差异
export const rejectProp = {
    show:false,
    title:'驳回差异',
    currentData:{},
    headerMessage:"说明：驳回差异默认回收商责任，需重新填写判责依据，确认驳回后将推送给回收商",
    judgements:[],
    isReset:false,
}
