<template>
  <el-dialog title="填写快递单号" :visible.sync="visible" :close-on-click-modal="false" width="450px">
    <el-form ref="formRef" :model="formData" :rules="formRule">
      <el-form-item label="快递公司" prop="expressCompany" label-width="90px">
        <el-select v-model="formData.expressCompany">
          <el-option label="顺丰" value="顺丰" />
          <el-option label="京东" value="京东" />
        </el-select>
        <!-- <el-input v-model="formData.expressCompany" placeholder="请输入快递单号" /> -->
      </el-form-item>
      <el-form-item label="快递单号" prop="expressNo" label-width="90px">
        <el-input v-model="formData.expressNo" placeholder="请输入快递单号" />
      </el-form-item>
    </el-form>
    <span slot="footer" class="dialog-footer">
      <el-button @click="visible = false">取消</el-button>
      <el-button type="primary" @click.native="confirm">确定</el-button>
    </span>
  </el-dialog>
</template>
<script>
import _api from "@/utils/request";
export default {
  name: "ExpressNoDia",
  data() {
    return {
      visible: false,
      row: {},
      formData: {
        expressCompany: '',
        expressNo: ''
      },
      formRule: {
        expressCompany: [{ required: true, message: '请选择快递公司', trigger: 'change' }],
        expressNo: [{ required: true, message: '请输入快递单号', trigger: 'change' }],
      }
    };
  },
  methods: {
    open(row) {
      this.row = row
      this.formData = {
        expressNo: row.expressNo,
        expressCompany: row.expressCompany
      }
      this.$nextTick(() => {
        this.$refs['formRef']?.clearValidate()
        this.visible = true
      })
    },
    close() {
      this.visible = false
    },
    confirm() {
      this.$refs['formRef'].validate((vaild) => {
        if (vaild) {
          _api.aprHonorSaveExpressNo({
            ...this.formData,
            subArgueNo: this.row.subArgueNo
          }).then(() => {
            this.$message.success('操作成功')
            this.close()
            this.$emit('confirm')
          })
        }
      })
    }
  }
};
</script>